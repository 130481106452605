












import { BootstrapIcon } from '@movecloser/ui-core'
import { Component } from 'vue-property-decorator'

import { AbstractState } from './_abstract'

/**
 * Displays the information after the successful form submission.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<Success>({
  name: 'Success',
  components: {
    BootstrapIcon,
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../atoms/UiHeading/UiHeading.vue'
    )
  }
})
export class Success extends AbstractState {}

export default Success
