// Copyright © 2022 Move Closer

/**
 * Payload for validation function
 *
 * @author Olga Milczek <olga.milczek@movecloser.com>
 */
export interface ValidationPayload {
  isValid: boolean
  errors?: string[]
}

/**
 * Payload for checkbox validation function
 *
 * @author Olga Milczek <olga.milczek@movecloser.com>
 */
export interface CheckboxListValidationPayload extends Omit<ValidationPayload, 'errors'> {
  errors?: Record<string, string[]>
}

/**
 * Method to validate checkbox list
 *
 * @param checked - list of selected checklist options
 * @param required - list of required options
 * @param translationKey: translation key for error messages
 *
 * @return CheckboxListValidationPayload
 *
 * @author Olga Milczek <olga.milczek@movecloser.com>
 */
export const validateCheckboxList = (checked: string[], required: string[], translationKey: string): CheckboxListValidationPayload => {
  if (!required || required.length < 0) {
    return {
      isValid: true
    }
  }

  let isValid = true
  const errors: Record<string, string[]> = {}

  required.forEach(option => {
    if (!checked.includes(option)) {
      isValid = false
      errors[option] = [translationKey]
    }
  })

  return { isValid, errors }
}

/**
 * Method to validate email
 *
 * @param email - string to check if its valid email
 * @param translationKey: translation key for error messages
 *
 * @returns ValidationPayload
 *
 * @author Olga Milczek <olga.milczek@movecloser.com>
 */
export const validateEmail = (email: string, translationKey: string): ValidationPayload => {
  let isValid = true
  const errors: string[] = []
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  if (email.length < 4) {
    isValid = false
    errors.push(translationKey + '.tooShort')
  }

  if (email.length > 60) {
    isValid = false
    errors.push(translationKey + '.tooLong')
  }

  if (!email.match(emailRegex)) {
    isValid = false
    errors.push(translationKey + '.wrongStructure')
  }

  return { isValid, errors }
}

/**
 * Method to validate name
 *
 * @param name - string to check if its valid name
 * @param translationKey: translation key for error messages
 *
 * @returns ValidationPayload
 *
 * @author Olga Milczek <olga.milczek@movecloser.com>
 */
export const validateName = (name: string, translationKey: string): ValidationPayload => {
  let isValid = true
  const errors: string[] = []

  if (name.length < 3) {
    isValid = false
    errors.push(translationKey + '.tooShort')
  }

  if (name.length > 50) {
    isValid = false
    errors.push(translationKey + '.tooLong')
  }

  if (/\s{2,}/.test(name)) {
    isValid = false
    errors.push(translationKey + '.toMuchBlankLines')
  }

  return { isValid, errors }
}
