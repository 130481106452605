


































































import { BootstrapButton, BootstrapCheck, BootstrapIcon, BootstrapInput } from '@movecloser/ui-core'
import { Component, Inject, Prop } from 'vue-property-decorator'
import { isEmpty } from 'lodash'
import { TranslateResult } from 'vue-i18n'

import { Color } from '../../../../../contracts'
import { IUserComService, NewsletterFormData } from '../../../../../services'

import { Agreement, MachineEvent } from '../../NewsletterForm.contracts'
import {
  validateCheckboxList, validateEmail, validateName
} from '../../NewsletterForm.helpers'

import { AbstractState } from './_abstract'

/**
 * Displays the newsletter form along with all current errors.
 *
 * @author Stanisław Gregor <stanislaw.gregor@movecloser.pl>
 */
@Component<DataEntry>({
  name: 'DataEntry',
  components: {
    BootstrapButton,
    BootstrapCheck,
    BootstrapIcon,
    BootstrapInput,
    UiHeading: () => import(
      /* webpackChunkName: "atoms/UiHeading" */
      '../../../../atoms/UiHeading/UiHeading.vue'
    ),
    UiMarkdown: () => import(
      /* webpackChunkName: "atoms/UiMarkdown" */
      '../../../../atoms/UiMarkdown/UiMarkdown.vue'
    )
  }
})
export class DataEntry extends AbstractState {
  /**
   * Optional heading.
   */
  @Prop({ type: String, required: false })
  public readonly heading?: string

  /**
   * Optional intro text (with Markdown support (will be parsed to HTML)).
   */
  @Prop({ type: String, required: false })
  public readonly intro?: string

  /**
   * Determines whether legend is visible.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public readonly isLegendHidden?: boolean

  /**
   * Determines legend text.
   */
  @Prop({ type: String, required: false, default: '' })
  public readonly legendText?: string

  /**
   * An instance of the `UserComService`.
   */
  @Inject()
  private readonly userComService!: IUserComService

  public readonly Color = Color

  /**
   * Array of agreements to render.
   */
  public readonly agreements: Agreement[] | undefined =
    this.$t('modules.components.molecules.NewsletterForm.partials.states.DataEntry.agreements') as unknown as Agreement[]

  /**
   * Object to store form errors
   */
  public errors: null | Record<string, string[]> = null

  /**
   * Data entered by the User.
   */
  public formData: NewsletterFormData = {
    agreements: [],
    email: '',
    isLegendHidden: false,
    legendText: '',
    name: ''
  }

  /**
   * GDPR-like text.
   */
  public readonly gdpr: TranslateResult | undefined =
    this.$t('modules.components.molecules.NewsletterForm.partials.states.DataEntry.gdpr')

  /**
   * Determines whether the component should be put in the "loading" state.
   */
  public isLoading: boolean = false

  /**
   * Determines whether the component has been provided with the correct `agreements` prop.
   */
  public get hasAgreements (): boolean {
    return typeof this.agreements !== 'undefined' &&
      Array.isArray(this.agreements) &&
      this.agreements.length > 0
  }

  /**
   * Determines whether there are any form errors to display.
   */
  public get hasErrors (): boolean {
    const errors = this.context.errors

    return typeof errors !== 'undefined' &&
      Array.isArray(errors) &&
      errors.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `gdpr` prop.
   */
  public get hasGDPR (): boolean {
    return typeof this.gdpr === 'string' && this.gdpr.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `heading` prop.
   */
  public get hasHeading (): boolean {
    return typeof this.heading === 'string' && this.heading.length > 0
  }

  /**
   * Determines whether the component has been provided with the correct `intro` prop.
   */
  public get hasIntro (): boolean {
    return typeof this.intro === 'string' && this.intro.length > 0
  }

  /**
   * Resolves the errors for the specified field.
   *
   * @param key - Key under which the errors are stored.
   */
  public getErrors (key: string): TranslateResult[] | undefined {
    if (!this.errors || !this.errors[key]) {
      return
    }

    return this.errors[key].map<TranslateResult>(k => this.$t(k))
  }

  /**
   * Handles the `@submit` event on the main `<form>` element.
   */
  public onSubmit (): void {
    this.errors = null

    if (!this.validateFormData(this.formData)) {
      return
    }

    this.isLoading = true

    this.submitForm()
      .then(() => { this.send(MachineEvent.SubmissionAccepted) })
      .catch(() => { this.send(MachineEvent.SubmissionRejected) })
      .finally(() => { this.isLoading = false })
  }

  /**
   * Submits the User's data to the `UserComService`.
   */
  private async submitForm (): Promise<void> {
    return this.userComService.subscribe(this.formData)
  }

  /**
   * Adds new errors to the errors array.
   *
   * @param key - Object key for the given errors.
   * @param errors - Array of errors to add.
   */
  private addErrors (key: string, errors: string[]): void {
    if (!this.errors) {
      this.errors = { [key]: errors }
      return
    }

    this.errors = {
      ...this.errors,
      [key]: errors
    }
  }

  /**
   * Checks if the selected agreements are valid.
   *
   * @param agreements - Array of the selected agreements.
   */
  private validateAgreements (agreements: string[]): boolean {
    if (!this.agreements || !this.hasAgreements) {
      return true
    }

    const requiredAgreements = this.agreements.filter(a => a.required).map<string>(a => `${a.value}`)

    const { isValid, errors } = validateCheckboxList(agreements, requiredAgreements,
      'modules.components.molecules.NewsletterForm.partials.states.DataEntry.errors.agreements')

    if (errors && !isEmpty(errors)) {
      Object.entries(errors).forEach(([key, errors]) => {
        this.addErrors(key, errors)
      })
    }

    return isValid
  }

  /**
   * Validate the given form data.
   *
   * @param formData - Newsletter form data entered by the User.
   *
   * @returns - Boolean value determining whether the provided data is valid.
   */
  private validateFormData (formData: NewsletterFormData): boolean {
    let isFormValid = true

    if (!this.validateName(formData.name)) {
      isFormValid = false
    }

    if (!this.validateEmail(formData.email)) {
      isFormValid = false
    }

    if (!this.validateAgreements(formData.agreements)) {
      isFormValid = false
    }

    return isFormValid
  }

  /**
   * Checks if the provided string is a valid e-mail address.
   *
   * @param email - The string to check.
   */
  private validateEmail (email: string): boolean {
    const { isValid, errors } = validateEmail(email,
      'modules.components.molecules.NewsletterForm.partials.states.DataEntry.errors.email')

    if (errors && errors.length > 0) {
      this.addErrors('email', errors)
    }

    return isValid
  }

  /**
   * Checks if the provided string is a valid name.
   *
   * @param name - The string to check.
   */
  private validateName (name: string): boolean {
    const { isValid, errors } = validateName(name,
      'modules.components.molecules.NewsletterForm.partials.states.DataEntry.errors.name')

    if (errors && errors.length > 0) {
      this.addErrors('name', errors)
    }

    return isValid
  }
}

export default DataEntry
